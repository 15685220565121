

const CONECTARTE_ACTIVITIES = [
    {
        'id': 'exa1',
        'name':'Examen inicial',
        'pond': 0
    },
    {
        'id': 'act1',
        'name':'Actividad 1',
        'pond': 35
    },
    {
        'id': 'act2',
        'name':'Actividad 2',
        'pond': 35
    },
    {
        'id': 'exa2',
        'name':'Examen Final',
        'pond': 30
    },
]

const CONECTARTE_SUBJECTS = [
    {
        'id': '1',
        'name': 'Teatro (Asociación Azoro)',
    },
    {
        'id':'2',
        'name':'Arte (Museo MARTE)',
    },
    {
        'id':'3',
        'name':'Ciudadanía',
    },
    {
        'id':'4',
        'name':'Nutrición',
    },
    {
        'id':'5',
        'name':'Formación Profesional',
    },
    {
        'id':'6',
        'name':'Inocuidad de Alimentos',
    },
    {
        'id':'7',
        'name': 'Huertos Hurbanos',
    }
]

const GASTROMOTIVA_ACTIVITIES = [
    {
        'id': 'exa1',
        'name':'Examen inicial',
        'pond': 0
    },
    {
        'id': 'act1',
        'name':'Actividad 1',
        'pond': 50
    },
    {
        'id': 'exa2',
        'name':'Examen Final',
        'pond': 50
    },
]

const GASTROMOTIVA_SUBJECTS = [
    {
        'id': '8',
        'name': 'Higiene e Inocuidad de Alimentos'
    },
    {
        'id':'9',
        'name':'Sociedad'
    },
    {
        'id':'10',
        'name':'Bases de la Gastronomía'
    },
    {
        'id':'11',
        'name':'Panadería'
    },
    {
        'id':'4',
        'name':'Nutrición'
    },
    {
        'id':'5',
        'name':'Formación Profesional'
    },
    {
        'id':'13',
        'name':'Cocina raíz'
    },
    {
        'id':'12',
        'name':'Servicio al Cliente'
    }
]

const GASTROMOTIVA_SUBJECTS_2 = [
    {
        'id': '8',
        'name': 'Higiene e Inocuidad de Alimentos'
    },
    {
        'id':'3',
        'name':'Ciudadanía'
    },
    {
        'id':'10',
        'name':'Bases de la Gastronomía'
    },
    {
        'id':'11',
        'name':'Panadería'
    },
    {
        'id':'4',
        'name':'Nutrición'
    },
    {
        'id':'5',
        'name':'Formación Profesional'
    },
    {
        'id':'12',
        'name':'Servicio al Cliente'
    }
]

export const SCORES_DB = [
    {
        'programmeId': '',
        'corresponsabilityId':'',
        'name': 'ConectArte 2019',
        'schema': 1,
        'subjects': CONECTARTE_SUBJECTS,
        'activities': CONECTARTE_ACTIVITIES
    },
    {
        'programmeId': '',
        'corresponsabilityId':'',
        'name': 'Gastromotiva 2019',
        'schema': 2,
        'subjects': GASTROMOTIVA_SUBJECTS,
        'activities': GASTROMOTIVA_ACTIVITIES  
    },{
        'programmeId': '',
        'corresponsabilityId':'',
        'name': 'Gastrmotiva 2020',
        'schema': 3,
        'subjects': GASTROMOTIVA_SUBJECTS_2,
        'activities': GASTROMOTIVA_ACTIVITIES
    }
]