import React, { Component } from 'react';
import {SCORES_DB} from '../data/dbScores'
import Panel from 'muicss/lib/react/panel';

class Scores extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentScores:{},
            attendance: null,
            schema: null,
            schemaName: "",
        }
    }

    componentDidMount(){
        this.getSchema();
        this.getCurrentScores();
        this.getAttendance();
    }

    getSchema = ()=>{
        var schema = 1;
        if(this.props.events){
            for(var i=0; i<this.props.events.length; i++){
                if('schema' in this.props.events[i]){
                    schema = this.props.events[i]['schema'];
                    break;
                }                
            }
        }
        this.setState({schema: schema});
    }

    getCurrentScores = ()=>{
        if(this.props.events){
            let currentScores = this.props.events.reduce((acc, curr)=>{
                if('scores' in curr){
                    curr.scores.forEach(element => {
                        if(!(element.subject in acc)){
                            acc[element.subject] = {};
                        }
                        for(var key in element){
                            if(key==="subject") continue;
                            acc[element.subject][key] = element[key];
                        }
                    });
                }
                return acc;
            }, {});
            this.setState({currentScores: currentScores});
        }
    }

    getAttendance = ()=>{
        if(this.props.events){
            let attendance = this.props.events.reduce((acc,curr)=>{
                if('asis' in curr){
                    //acc = acc + parseInt(curr['asisTransaction']);
                     acc = parseInt(curr['asis']); // asume order by transactionDate asc;
                }
                return acc;
            }, 0);
            this.setState({attendance, attendance});
        }
    }

    getDbIndex = (schema)=>{
        if(this.state.schema){
            for(var i=0;i<SCORES_DB.length;i++){
                if(SCORES_DB[i].schema===schema){
                    return i;
                }
            }
        }
        return -1;
    }

    getScoresData = ()=>{
        // TODO: get scores db from props. 
        var scoresTableArr = [];
        var finalAvg = 0;
        let index = this.getDbIndex(this.state.schema);
        if(index>=0){
            SCORES_DB[index].subjects.forEach((subject)=>{
                var activityAvg = 0;
                var columns = [];
                columns.push({'value':subject.name, 'type':'normal'})
                SCORES_DB[index].activities.forEach(activity=>{
                    var obj = {'type':'normal'};
                    let score = 0;
                    if(subject.id in this.state.currentScores){
                        if(activity.id in this.state.currentScores[subject.id]){
                            score = Number(this.state.currentScores[subject.id][activity.id]);
                            obj['value'] = (score/10).toFixed(1)
                        }
                    }
                    activityAvg+=score*activity.pond/100;            
                    columns.push(obj);
                })
                finalAvg+=activityAvg;
                columns.push({'type':'normal', 'value':(activityAvg/10).toFixed(2)});
                scoresTableArr.push(columns);
            })
            var columns = [{}];
            SCORES_DB[index].activities.forEach(()=>{
                columns.push({});
            })
            finalAvg = finalAvg/SCORES_DB[index].subjects.length;
            columns.push({'type':'bold', 'value': (finalAvg/10).toFixed(2)})
            scoresTableArr.push(columns);
        }        
        return scoresTableArr;
    }

    getSchemaName = ()=>{
        let index = this.getDbIndex(this.state.schema);
        if(index>=0){
            return SCORES_DB[index].name;
        }
        return "";
    }

    render(){
        var scoresData = this.getScoresData();
        var schemaName =  this.getSchemaName();
        return(
            <div>
                <h1>{schemaName}</h1><br/>
                <Panel>
                    Asistencias a la fecha: <span style={{"color":"#1976D2"}}> {this.state.attendance}</span>
                </Panel>
                <table className="mui-table mui-table--bordered">
                    <thead>
                        <tr>
                            <th>Asignaturas</th>
                            {
                                SCORES_DB[0].activities.map((act, idx)=>{
                                    return(
                                        <th key={idx}>{act.name+" ("+act.pond+" %)"}</th>
                                    )
                                })
                            }
                            <th>Nota final</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            scoresData.map((scores, idx)=>{
                                return(
                                    <tr key={idx}>
                                    {scores.map((column, idx2)=>{
                                        return(
                                            <td key={idx2}>
                                                { column.type=="bold"?
                                                    <strong>{column.value}</strong>
                                                    :
                                                    <span>{column.value}</span>
                                                }
                                            </td>
                                        )
                                    })}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Scores;