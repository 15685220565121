import React, { Component } from 'react';
import logo from './PMA-MAPS_medium.jpg';
import './App.css';
import Container from 'muicss/lib/react/container';
import Row from 'muicss/lib/react/row';
import Col from 'muicss/lib/react/col';
import Form from 'muicss/lib/react/form';
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';
import Loader from 'react-loader-spinner'
import Scores from './components/Scores';

import {lookup, asis_lookup} from './utils'

const BASE_URL = document.location.protocol+"//"+document.location.host;
const CONT_ASSIGNMENTS = 7;

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      documentId: "",
      showScores: false,
      loading: false,
      beneficiary:null,
      events:[],
      error: {isError: false, message: ""}
    }
  }

  clearData = ()=>{
    this.setState({
      documentId: "",
      showScores: false,
      loading: false,
      beneficiary:null,
      events:[],
      error: {isError: false, message: ""}
    });
  }

  setDocumentId = (event)=>{
    event.preventDefault();
    this.setState({documentId:event.target.value});
  }

  getScores = (event)=>{
    event.preventDefault();
    var request = require('request');
    
    var options = {
      url: BASE_URL+"/.netlify/functions/getTransactions",
      qs:{document_id:this.state.documentId}
    }
    var self = this;
    this.setState({loading: true});

    request.get(options, function(e, r, body){
      self.setState({loading: false});
      if(!e && r.statusCode===200){
        var transactions = JSON.parse(body);
        if(transactions.length>0){
          var beneficiary = transactions[0].beneficiary;
          var data = transactions.map(item=>{
            return JSON.parse(item.data);
          });
          
          self.setState({
            showScores:true, 
            beneficiary: beneficiary,
            events: data
          });
        }
        else{
          self.setState({error:{isError:true, message:"El estudiante aún no tiene registros en el programa."}});
        }        
      }
      else{
        var errors = JSON.parse(body);
        self.setState({error:{isError:true, message:errors.error}});
      }
    })
  }

  render() {    
    var beneficiary = this.state.beneficiary;

    return (
      <div className="App">
        <div hidden={this.state.showScores?true:false}>
          <div className="App-header">              
            <Container>
              <div className="mui--text-center" hidden={this.state.loading?true:false}>
                <div>
                <img src={logo}/>
                <br/>
                </div>
                <div className="mui--text-dark mui--text-headline">Consulta de Calificaciones</div>
                <br/><br/>
                <div className="ErrorBox" hidden={this.state.error.isError?false:true}>
                  <p>{this.state.error.message}</p>
                </div>
                <Form>
                  <Input id="documentId" name="documentId" label="Documento de identidad" required={true} onChange={this.setDocumentId} value={this.state.documentId}/>
                  <Button variant="raised" color="primary" onClick={this.getScores}>Consultar</Button>
                </Form>
              </div>
              <div className="mui--text-center" hidden={this.state.loading?false:true}>
                <Loader type="Rings" color="#0A6EB4" height="100" width="100"/>  
              </div>
            </Container>
          </div>
        </div>       
        <div>            
          <Container>
            <div>              
              {beneficiary?              
              <div>
                <img src={logo} style={{'height':'100px'}}/>
                <br />
                <Row>
                  <Col xs={10}>
                    <div className="mui--text-title">{beneficiary.firstName+" "+beneficiary.lastName}</div>
                  </Col>
                  <Col xs={2}>
                    <Button size="small" color="primary" variant="flat" onClick={this.clearData}>Salir</Button>
                  </Col>
                </Row>
                <Scores events={this.state.events.slice(0).reverse()}/>
              </div>       
              :
              <div/>
              }
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default App;
